import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/stable';
import 'core-js';
import './polyfill'
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {
  ApolloProvider,
  ApolloClient,
  // split,
  // HttpLink,
  createHttpLink,
  InMemoryCache,
  // concat,
  // ApolloLink,
} from '@apollo/client';
// import { WebSocketLink } from '@apollo/client/link/ws';
// import { getMainDefinition } from '@apollo/client/utilities';
import { setContext } from '@apollo/client/link/context';
import { icons } from './assets/icons'

import { Provider } from 'react-redux'
import store from './store'

// React.icons = icons

// const httpLink = new HttpLink({
//   uri: 'https://graphql-engine.thetensortech.com/v1/graphql'
// });

// // const httpLink = createHttpLink({
// //   uri: 'https://graphql-engine.thetensortech.com/v1/graphql'
// // });

// const wsLink = new WebSocketLink({
//   uri: 'wss://graphql-engine.thetensortech.com/v1/graphql',
//   options: {
//     reconnect: true,
//     lazy: false,
//     connectionParams: {
//       headers: {
//         "x-auth": localStorage.getItem('token') || null,
//       }
//     },
//   }
// });


// const splitLink = split(
//   ({ query }) => {
//     const definition = getMainDefinition(query);
//     return (
//       definition.kind === 'OperationDefinition' &&
//       definition.operation === 'subscription'
//     );
//   },
//   wsLink,
//   httpLink,
// );

// const authMiddleware = new ApolloLink((operation, forward) => {
//   // add the authorization to the headers
//   operation.setContext(({ headers = {} }) => ({
//     headers: {
//       ...headers,
//       "x-auth": localStorage.getItem('token') || null,
//     }
//   }));
//   return forward(operation);
// })



// const client = new ApolloClient({
//   link: concat(authMiddleware, splitLink),
//   // link: splitLink,
//   cache: new InMemoryCache(),
//   credentials: 'include',
// });



React.icons = icons
const httpLink = createHttpLink({
  uri: 'https://graphql-engine.thetensortech.com/v1/graphql'
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("token");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      "x-auth": token ,
    }
  }
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          Match: {
            merge(existing = [], incoming= []) {
              return [...incoming];
            },
            // read(_, { args, toReference }) {
            //   if(args.where.Id){
            //     console.log(args.where.Id._eq)
            //     console.log(toReference)
            //     console.log(toReference({
            //       __typename: 'Match',
            //       Id: args.where.Id ? args.where.Id._eq: args.where.Id,
            //     }))
            //   }
            //   return toReference({
            //     __typename: 'Match',
            //     Id: args.where.Id ? args.where.Id._eq: args.where.Id,
            //   });
            // },
          },
        },
      },
      Match: {
        fields: {
          MarketsList: {
            merge(existing = [], incoming= []) {
              return [...incoming];
            },
          },
        },
        keyFields: ["Id"],
      },
      Competition: {
        keyFields: ["Id"],
      },
      Region: {
        keyFields: ["Id"],
      },
      Market: {
        fields: {
          Selections: {
            merge(existing = [], incoming= []) {
              return [...incoming];
            },
          },
        },
        keyFields: ["Id"],
      },
      MarketType: {
        keyFields: ["Id"],
      },
      Selection: {
        keyFields: ["Id"],
      },
      SelectionType: {
        keyFields: ["Id"],
      },
      query_cache: {
        keyFields: ["cache_key"],
      }
    },
  })
});


ReactDOM.render(
  <ApolloProvider client={client}>
    <Provider store={store}>
      <App/>
    </Provider>
  </ApolloProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
